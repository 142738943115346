<template>
	<div>
		<div class="input_info">
			<p class="input_text">用户名称：</p>
			<el-input v-model="requestParams.username" placeholder="请输入用户名称" class="w-50" clearable
				@keyup.native.enter="getUserList" />
			<el-button type="primary" style="margin-left: 10px" @click="getUserList">搜索</el-button>
			<!-- 客服id：<el-input v-model="requestParams.cs_id" placeholder="请输入客服id" size="small" clearable /> -->
			<el-button type="primary" plain icon="el-icon-plus" style="margin-left: 10px" @click="dialogVisible = true">
				添加</el-button>
		</div>

		<div class="add_dialog">
			<el-dialog :title="`${form.id ? '编辑' : '添加'}用户信息`" :visible.sync="dialogVisible" width="600px"
				@close="handleDialogClose" :before-close="handleClose" center>
				<div class="addAdmin">
					<p class="addAdmin_CharacterSet">用户名：</p>
					<div class="addAdmin_select">
						<el-input v-model="form.username" placeholder="请输入用户名" clearable />
					</div>
				</div>

				<div class="addAdmin">
					<p class="addAdmin_CharacterSet">密码：</p>
					<div class="addAdmin_select">
						<el-input v-model="form.password" placeholder="请输入密码" clearable />
					</div>
				</div>

				<div class="addAdmin">
					<p class="addAdmin_CharacterSet">海鼎编码：</p>
					<div class="addAdmin_select">
						<el-input v-model="form.hd_code" placeholder="请输入海鼎编码" clearable />
					</div>
				</div>

				<div class="addAdmin">
					<p class="addAdmin_CharacterSet">国家：</p>
					<div class="addAdmin_select">
						<el-select v-model="form.country" clearable placeholder="请选择" filterable>
							<el-option v-for="item in countryList" :key="item.code" :label="item.name"
								:value="item.code">
								{{ item.name }}
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="addAdmin">
					<p class="addAdmin_CharacterSet">客服id：</p>
					<div class="addAdmin_select">
						<el-select v-model="form.cs_id" clearable placeholder="请选择" filterable>
							<el-option v-for="item in serviceData.data" :key="item.id" :label="item.user_name"
								:value="item.id">
								{{ item.user_name }}
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="addAdmin">
					<p class="addAdmin_CharacterSet">用户级别：</p>
					<div class="addAdmin_select">
						<el-radio v-model="form.user_level" :label=1>A</el-radio>
						<el-radio v-model="form.user_level" :label=2>B</el-radio>
						<el-radio v-model="form.user_level" :label=3>C</el-radio>
					</div>
				</div>
        <div class="addAdmin">
					<p class="addAdmin_CharacterSet">用户类型：</p>
					<div class="addAdmin_select">
						<el-radio v-model="form.long_time" label="0">长期用户</el-radio>
						<el-radio v-model="form.long_time" label="1">限时用户</el-radio>
					</div>
				</div>

				<div class="addAdmin" v-show="form.long_time>0">
					<p class="addAdmin_CharacterSet">到期时间：</p>
					<div class="addAdmin_select">
						<el-date-picker type="datetime" value-format="timestamp" v-model="form.end_time"
							placeholder="选择到期日期">
						</el-date-picker>
					</div>
				</div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">上级用户：</p>
          <div class="addAdmin_select">
            <el-select v-model="form.pid" clearable placeholder="请选择" filterable>
              <el-option v-for="item in tableData" :key="item.id" :label="item.username"
                         :value="item.id">
                {{ item.username }}
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">是否展示价格：</p>
          <div class="addAdmin_select">
            <el-select v-model="form.show_price" clearable placeholder="请选择" filterable>
              <el-option v-for="item in [{name:'展示',value:1},{name:'不展示',value:2}]" :key="item.name" :label="item.name"
                         :value="item.value">
                {{ item.name }}
              </el-option>
            </el-select>
          </div>
        </div>


				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button v-loading="isLoading.table" type="primary" @click="handleDialogConfirm">确 定</el-button>
				</div>
			</el-dialog>
		</div>

		<div class="table_style">
			<el-table ref="multipleTable" v-loading="isLoading.table" header-align="center" border class="main-table"
				:data="tableData" tooltip-effect="dark" style="width: 100%">
				<el-table-column prop="id" label="id" align="center" />
				<el-table-column prop="hd_code" label="海鼎编码" align="center" />
				<el-table-column prop="username" label="用户名称" align="center" />
				<el-table-column prop="country" label="国家" align="center" />
        <el-table-column prop="user_level" label="客户级别" align="center" >
          <template v-slot="{row}">
            <span v-if="row.user_level === 1">A</span>
            <span v-if="row.user_level === 2">B</span>
            <span v-if="row.user_level === 3">C</span>
          </template>
        </el-table-column>
				<el-table-column prop="cs_id" label="客服id" align="center">
					<template v-slot="{row}">
						<span v-for="value in serviceData.data" :key="value.id">
							{{ row.cs_id === value.id ? value.user_name :'' }}
						</span>
					</template>
				</el-table-column>
        <el-table-column prop="user_level" label="是否展示价格" align="center" >
          <template v-slot="{row}">
            <el-switch
              v-model="row.show_price"
              active-text="展示"
              inactive-text="不展示"
              :active-value=1
              :inactive-value=0 @change="handleUpdateShowPrice(row)">
            </el-switch>
<!--            <span v-if="row.show_price === 1">展示</span>-->
<!--            <span v-if="row.show_price === 0">不展示</span>-->
          </template>
        </el-table-column>
				<el-table-column prop="operation" label="操作" align="center" width="300">
					<template v-slot="{ row, $index }">
						<div v-if="loginid === 2">
							<el-button type="warning" size="small" plain icon="el-icon-edit-outline"
								style="margin-right: 10px" @click.stop="handleEditAuth(row)">编辑</el-button>
							<el-popover trigger="click" placement="top">
								<p>
									你确认要删除这位用户吗? <strong>{{ row.username }}</strong>
								</p>
								<div style="text-align: right; margin: 0">
									<el-button size="mini" type="text" @click="hidePopover()">取消</el-button>
									<el-button type="primary" size="mini" @click="handleDeleteAuth(row.id, $index)">确认
									</el-button>
								</div>
								<el-button slot="reference" plain icon="el-icon-delete" type="danger" size="small"
									@click.stop>删除</el-button>
							</el-popover>
						</div>
						<div v-if="loginid !== 2">-</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div ref="footer" class="footer">
			<el-pagination :key="reloadPaginate" :current-page="pagination.page" :page-size="pagination.pageSize"
				:page-sizes="[10, 20, 50, 100, 200]" layout="total,sizes, prev, pager, next, jumper"
				:total="pagination.total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				background />
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				//客服数据
				serviceData: {
					data: [],
				},
				//国家列表
				countryList: [],
				requestParams: {
					username: "",
				},
				form: {
					username: "",
					hd_code: "",
					password: "",
					country: "",
					cs_id: "",
					long_time: "0",
					end_time: 0,
          user_level:1,
          show_price:0
				},
				tableData: [],
				isLoading: {
					table: false,
				},
				reloadPaginate: 0,
				pagination: {
					total: 0,
					page: 1,
					pageSize: 10,
				},
				dialogVisible: false,
				loginid: 0,

			};
		},
		created() {
			this.getUserList();
			this.getAdminAll();
			this.getCountry();
			const adminInfoStr = sessionStorage.getItem("user")
			let adminInfo = {}
			if (adminInfoStr) adminInfo = JSON.parse(adminInfoStr)
			this.loginid = adminInfo.admin_info.id
		},
		methods: {
			//获取全部管理
			getAdminAll() {
				this.$axios.get("/a1/admin/all").then((res) => {
					this.serviceData.data = res.data.data;
				});
			},
			//获取国家
			getCountry() {
				this.$axios.get("/a1/country").then((res) => {
					this.countryList = res.data.data;
				});
			},
			// 分页跳转
			handleSizeChange(val) {
				this.pagination.pageSize = val;
				this.getUserList();
			},
			handleCurrentChange(val) {
				this.pagination.page = val;
				this.getUserList();
			},
			//弹窗关闭
			handleClose(done) {
				done();
			},
			//获取到客户列表
			getUserList() {
				this.tableData = [];
				this.pagination.total = 0;

				let params = {
					page_size: this.pagination.pageSize,
					page: this.pagination.page,
				}

				if (Number(this.requestParams.username) > 0) {
					params.hd_code = this.requestParams.username
				} else {
					params.username = this.requestParams.username
				}

				this.$axios
					.get("/a1/user", {
						params: params,
					})
					.then((res) => {
						const {
							data
						} = res.data;
						this.pagination.total = data.total;
						this.tableData = data.data;
						this.reloadPaginate++;
          });
			},
			// 编辑用户点击
			handleEditAuth(row) {
				const formData = JSON.parse(JSON.stringify(row));
				this.form = {
					username: "",
					hd_code: "",
					password: "",
					country: "",
					cs_id: "",
					long_time: "0",
					end_time: 0,
          user_level:1
				}
				this.form.username = formData.username;
				this.form.hd_code = formData.hd_code;
				this.form.password = "";
				this.form.country = formData.country;
				this.form.cs_id = formData.cs_id;
				this.form.id = formData.id;
				this.form.long_time = "0"
				this.form.user_level = formData.user_level
				this.form.pid = formData.pid
				this.form.show_price = formData.show_price

				this.form.long_time = formData.long_time + "";
				if (formData.end_time) {
					//1646992533
					this.form.end_time = formData.end_time * 1000;
				} else {
					this.form.end_time = ""
				}

				this.dialogVisible = true;
				//编辑用户
				this.serviceData.value = formData.cs_id;
			},
			// 编辑跟新增
			handleDialogConfirm() {
				this.isLoading.table = true;


				this.form.long_time = parseInt(this.form.long_time)
				if (this.form.long_time > 0) {
					this.form.end_time = parseInt(this.form.end_time) / 1000
				} else {
					this.form.end_time = 0
				}

        if (this.form.id) {
					this.$axios
						.put(
							`/a1/user/${this.form.id}`,
							Object.assign({}, this.form, {
								hd_code: parseInt(this.form.hd_code),
								cs_id: parseInt(this.form.cs_id),
							})
						)
						.then(() => {
							this.$message.success("修改成功");
							this.getUserList();
							this.dialogVisible = false;
						})
						.finally(() => {
							this.isLoading.table = false;
						});

					return;
				}
				this.$axios
					.post(
						"/a1/user/",
						Object.assign({}, this.form, {
							hd_code: parseInt(this.form.hd_code),
							cs_id: parseInt(this.form.cs_id),
						})
					)
					.then(() => {
						this.$message.success("新增成功");
						this.getUserList();
						this.dialogVisible = false;
					})
					.finally(() => {
						this.isLoading.table = false;
					});
			},
			// 清空表格数据
			handleDialogClose() {
        this.form = this.$options.data().form
			},
			// 删除
			handleDeleteAuth(id, index) {
				this.isLoading.table = true;
				this.$axios
					.delete(`/a1/user/${id}`)
					.then(() => {
						this.tableData.splice(index, 1);
						this.$notify({
							title: "成功",
							message: "删除用户",
							type: "success",
						});
					})
					.catch(() => {
						this.$notify.error({
							title: "失败",
							message: "删除用户",
						});
					})
					.finally(() => {
						this.hidePopover();
						this.isLoading.table = false;
					});
			},
			hidePopover() {
				this.$refs.footer.click();
			},
      handleUpdateShowPrice(row){
        this.$axios.put(
          `/a1/user/${row.id}`,
          Object.assign({show_price:row.show_price}, {
            hd_code: parseInt(row.hd_code),
            cs_id: parseInt(row.cs_id),
          })
        ).then(() => {
            this.$message.success("修改成功");
            this.getUserList();
          })
      }
		},
	};
</script>
<style lang="scss" scoped>
	.input_info {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 10px 0 10px 10px;
	}

	.input_text {
		min-width: 100px;
		padding: 10px;
		color: #889aa4;
	}

	.w-50 {
		width: 500px;
	}

	.footer {
		margin: 100px;
		text-align: center;
	}

	.addAdmin {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 50px;

		&_CharacterSet {
			font-size: 15px;
			width: 120px;
			text-align: right;
		}

		&_select {
			flex: 1;
		}
	}

	.table_style {
		margin-left: 10px;
	}
</style>
